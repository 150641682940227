<template>
  <div class="content pad-bottom--vxl">
    <div class="grid-container pad-top bg--white">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <h1>Terms and Conditions</h1>
          <h3 class="pad-top">1. ACCEPTANCE</h3>
          <p class="marg-top">
            The ipsen-pcacademy.com website (“Website”) has been developed and is supplied by Ipsen
            Limited, 190 Bath Road, Slough, Berkshire SL1 3XE , United Kingdom (hereafter, together
            with its affiliates, referred to as “IPSEN”). Your access to and use of the Website is
            subject to the following Terms and Conditions and all applicable laws. By accessing the
            Website, you accept these Terms and Conditions without limitation or qualification.
          </p>
          <h3>2. USE OF INFORMATION</h3>
          <p>
            This Website is for the PCA academy, for healthcare professionals only. The PCA academy
            is an educational programme consisting of a series of webinars delivered to UK
            Specialist Registrars (SpRs) to further prepare them for their transition from training
            into a consultant post. This programme is IPSEN-initiated and funded. 
          </p>
          <p>
            You may not distribute, modify, transmit, reuse, repost, or use any of the information on
            the Website for commercial purposes. You should assume that everything you see or read on
            this Website is protected by copyright unless otherwise noted and may not be used except
            as provided in these Terms and Conditions.
          </p>
          <p>
            Except as otherwise permitted in this paragraph, IPSEN neither warrants nor represents
            that your use of materials displayed on the Website will not infringe rights of third
            parties not owned by or affiliated with IPSEN. With the exception of the foregoing
            limited authorization, no license to or right in the information, or any copyright of
            IPSEN or of any other party is granted to or conferred upon you.
          </p>
          <p>
            This Website may contain or reference proprietary information, technologies, products,
            processes or other proprietary rights of IPSEN and/or other parties. No license to or
            right in any such trademarks, patents, trade secrets, technologies, products, processes
            and other proprietary rights of IPSEN and/or other parties is granted to or conferred
            upon you.
          </p>
          <h3>3. DISCLAIMER OF WARRANTIES</h3>
          <p>
            While IPSEN uses reasonable efforts to ensure that the information contained in the
            Website is accurate and up to date, such information may contain inaccuracies or
            typographical errors. IPSEN reserves the right to make changes, corrections and/or
            improvements to the information at any time without notice. IPSEN makes no warranties or
            representations as to the accuracy of any of the information and assumes no liability or
            responsibility for any errors or omissions in the content of the Website.
          </p>
          <p>
            ALL INFORMATION IS PROVIDED “AS IS”. IPSEN PROVIDES NO WARRANTIES ABOUT THE COMPLETENESS
            OR ACCURACY OF THE INFORMATION ON THIS WEBSITE, OR ITS POSSIBLE USES. CONSEQUENTLY,
            USERS SHOULD CAREFULLY EVALUATE THE INFORMATION BEFORE USE. YOU MAY USE THIS INFORMATION
            AT YOUR OWN DISCRETION AND RISK. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IPSEN, ANY OTHER
            IPSEN GROUP COMPANY, AND ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING OR DELIVERING
            THIS WEBSITE, DISCLAIM ANY WARRANTIES OR LIABILITY FOR ANY DIRECT, INCIDENTAL,
            CONSEQUENTIAL, INDIRECT OR PUNITIVE DAMAGES ARISING OUT OF ACCESS TO, USE OF OR INABILITY
            TO USE THIS WEBSITE, OR ANY ERRORS OR OMISSIONS IN THE CONTENT OF THE WEBSITE.
          </p>
          <p>
            IPSEN also assumes no responsibility, and shall not be liable for, any damage to, or
            viruses that may infect, your equipment or other property inflicted by your access to or
            use of the information.
          </p>
          <h3>4. INFORMATION YOU SUPPLY</h3>
          <p>
            Except for information covered by the Privacy Notice, if such a notice is applicable, any
            communication or material you transmit to the Website, including any data, questions,
            comments, suggestions or the like (if applicable) is, and will be treated as,
            non-confidential and non-proprietary.
          </p>
          <h3>5. CONSEQUENCES</h3>
          <p>
            If IPSEN becomes aware that you have violated any of the Terms and Conditions contained
            herein, it may immediately take corrective action, including preventing you from using
            the Website, at any moment and without notice. If IPSEN has been affected or injured by
            your violation it may, at its sole discretion, seek to recover damages from you.
          </p>
          <h3>6. REVISIONS</h3>
          <p>
            IPSEN may at any time revise these Terms and Conditions by updating this posting and
            notifying the user by sufficient means (such as an email or a pop-up window in the
            Website, by which you will be asked to give your consent to the amended version). The
            revised Terms and Conditions enter into effect within 30 calendar days after such an
            update. If you disagree with the new Terms and Conditions, you should indicate so through
            the mechanism provided by the relevant email or pop-up, and subsequently stop accessing
            and using the Website. You should periodically visit this page to review the current
            Terms and Conditions to which you are bound. IPSEN reserves the right to discontinue this
            Website at any time without liability to you or anyone else. You will receive prior
            notice of such a discontinuation.
          </p>
          <h3>7. CREDITS</h3>
          <p>
            Videos, photographs: Ipsen Limited
          </p>
          <h3>8. INTELLECTUAL PROPERTY</h3>
          <p>
            The content of this Website is fully protected by the applicable intellectual property
            rights. All materials contained in this Website may not be reproduced, displayed or
            further used in any manner whatsoever without the prior written permission of IPSEN.
          </p>
          <h3>9. REPORTING SIDE EFFECTS</h3>
          <p>
            Adverse events should be reported. Reporting forms and information can be found at
            <span class="link-underline">www.mhra.gov.uk/yellowcard </span> or search for MHRA
            Yellow Card in Google Play or Apple App Store. Adverse events should also be reported to
            Ipsen via email at
            <a href="mailto:pharmacovigilance.uk-ie@ipsen.com" class="text-purple link-underline">
            pharmacovigilance.uk-ie@ipsen.com</a> or phone on 01753 627777.
          </p>
          <h3>10. SEVERABILITY</h3>
          <p>
            The invalidity or unenforceability of any provision of these Terms and Conditions shall
            not affect the validity or enforceability of any other provision, which shall remain in
            full force and effect, except where the invalidity or unenforceability relates to an
            essential term of these Terms and Conditions.
          </p>
          <h3>11. GOVERNING LAWS AND DISPUTE RESOLUTION</h3>
          <p>
            These Terms and Conditions and your use of the Website shall be governed by the laws of
            the England and Wales, except for the provisions of international private law.
          </p>
          <p>
            Disputes that cannot be resolved amicably, will be submitted to the courts and tribunals
            of the England and Wales.
          </p>
          <p class="pad-top">
            Last updated August 2020
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsConditions',
};
</script>
